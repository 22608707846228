import { useMemo } from 'react';

import { areasRepository } from 'infrastructure/areas/areasRepository';
import { LAYERS_KEYS } from 'components/layerManager/draggableLayersKeys';
import { type DraggableSection } from './types';
import { DemItem, ExtentItem, HillshadeItem, InfrastructureItem, SatelliteItem } from '../commonContent';

export const useDraggableLayerManagerContent = (areaName: string, extraSections?: DraggableSection[]) => {
  const { layersList } = areasRepository.useFetchTopographyLayers(areaName);
  const { shapes } = areasRepository.useFetchInfrastructureShapes(areaName);

  const memoizedSections = useMemo(
    () => [
      { id: LAYERS_KEYS.EXTENT, name: 'extent', content: <ExtentItem id={LAYERS_KEYS.EXTENT} /> },
      ...(shapes.length
        ? [
            {
              id: LAYERS_KEYS.INFRASTRUCTURE,
              name: 'infrastructure',
              content: <InfrastructureItem {...{ shapes }} id={LAYERS_KEYS.INFRASTRUCTURE} />,
            },
          ]
        : []),
      ...(extraSections || []),
      ...(layersList.length
        ? [{ id: LAYERS_KEYS.DEM, name: 'dem', content: <DemItem {...{ layersList }} id={LAYERS_KEYS.DEM} /> }]
        : []),
      ...(layersList.length
        ? [
            {
              id: LAYERS_KEYS.HILLSHADE,
              name: 'hillshade',
              content: <HillshadeItem {...{ layersList }} id={LAYERS_KEYS.HILLSHADE} />,
            },
          ]
        : []),
      { id: LAYERS_KEYS.SATELLITE, name: 'satellite', content: <SatelliteItem id={LAYERS_KEYS.SATELLITE} /> },
    ],
    [extraSections, layersList, shapes],
  );

  const nonNullSections = memoizedSections.filter((section) => section.id);

  return { sections: nonNullSections };
};
