import chroma from 'chroma-js';

import { type LeafletBounds } from 'common/types/mapData';
import { buildCoordinatesOnlyTooltipStringHelper } from 'common/utils/map';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { DataLayer } from 'components/map';
import { MapClick } from 'components/map/tooltip/MapClick';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { titilerInfoService } from 'services/titiler/titilerInfoService';
import { getBoundsFromCogInfo } from 'services/titiler/utils';
import { SNOW_COVER_COLOR } from 'domain/water/colors';

interface SnowCoverMapLayerProps {
  sourceUrl: string;
}

export const SnowCoverMapLayer = ({ sourceUrl }: SnowCoverMapLayerProps) => {
  const isVisible = useLayerManagerStore.use.isDataLayerVisible();

  const colormapArray = [
    [
      [0.0001, 1.0001],
      [...chroma(SNOW_COVER_COLOR).rgb(), 255],
    ],
  ];

  const titilerUrl = titilerApiAdapter.buildGenericMonocolorCogUrl(`${sourceUrl}`, {
    expression: 'b1',
    colormap: JSON.stringify(colormapArray),
  });

  const { info } = titilerInfoService.useGetInfoGeneric(sourceUrl);
  const bounds = getBoundsFromCogInfo(info) as LeafletBounds | undefined;

  if (!titilerUrl || !bounds) {
    return null;
  }

  return (
    <>
      <DataLayer
        isVisible={isVisible}
        layerName={'snowCoverLayer'}
        zIndex={431}
        cogUrl={titilerUrl}
        bounds={bounds}
        opacity={1}
      />
      {isVisible && <MapClick tooltipStringBuilder={buildCoordinatesOnlyTooltipStringHelper} />}
    </>
  );
};
