import { useGetAoiName } from 'common/hooks/getAoiName';
import { useTimelineService } from 'components/Timeline/timelineService';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { SnowCoverMapLayer } from 'views/water/snow/SnowCoverMapLayer';

export const SnowCoverMapTimeSeriesLayer = () => {
  const areaName = useGetAoiName();
  const { timestamp } = useTimelineService();

  const { results } = waterRepository.useFetchSnowCoverResults(areaName);

  const selectedResultUrl = results.find((result) => result.img_date === timestamp)?.img_url;

  if (!selectedResultUrl) {
    return null;
  }

  return <SnowCoverMapLayer sourceUrl={selectedResultUrl} />;
};
