import { useGetAoiName } from 'common/hooks/getAoiName';
import { explorationRepository } from 'infrastructure/exploration/explorationRepository';
import { DraggableLayerManager } from 'components/layerManager/draggableLayerManager/DraggableLayerManager';
import { useDraggableLayerManagerContent } from 'components/layerManager/draggableLayerManager/useDraggableLayerManagerContent';
import { LAYERS_KEYS } from 'components/layerManager/draggableLayersKeys';
import { LineamentsLayerManager } from './lineaments';
import { IndicesLayerManager } from './indices';
import { MineralsLayerManagerMtmf, MineralsLayerManagerSam, MineralsLayerManagerMl } from './minerals';
import { CompositesLayerManager } from './composites';
import { ClustersLayerManager } from './clusters';
import { useExplorationLayerManagerStore } from './explorationLayerManagerStore';

export const ExplorationLayerManager = () => {
  const sectionsOrderRecord = useExplorationLayerManagerStore.use.sectionsOrderRecord();
  const setSectionsOrderRecord = useExplorationLayerManagerStore.use.setSectionsOrderRecord();

  const areaName = useGetAoiName();

  if (!areaName) {
    throw new Error('Area name is not defined');
  }

  const { spectralClassifiers: classifiers } = explorationRepository.useFetchSpectralClassifiers();

  const { sections } = useDraggableLayerManagerContent(areaName, [
    ...(areaName
      ? [
          {
            id: LAYERS_KEYS.LINEAMENTS,
            name: 'lineaments',
            content: <LineamentsLayerManager {...{ areaName }} id={LAYERS_KEYS.LINEAMENTS} />,
          },
          { id: LAYERS_KEYS.INDICES, name: 'indices', content: <IndicesLayerManager id={LAYERS_KEYS.INDICES} /> },
          {
            id: LAYERS_KEYS.SAM,
            name: 'sam',
            content: <MineralsLayerManagerSam {...{ classifiers }} id={LAYERS_KEYS.SAM} />,
          },
          {
            id: LAYERS_KEYS.MTMF,
            name: 'mtmf',
            content: <MineralsLayerManagerMtmf {...{ classifiers }} id={LAYERS_KEYS.MTMF} />,
          },
          {
            id: LAYERS_KEYS.ML,
            name: 'ml',
            content: <MineralsLayerManagerMl {...{ classifiers }} id={LAYERS_KEYS.ML} />,
          },
          { id: LAYERS_KEYS.CLUSTERS, name: 'clusters', content: <ClustersLayerManager id={LAYERS_KEYS.CLUSTERS} /> },
          {
            id: LAYERS_KEYS.COMPOSITES,
            name: 'composites',
            content: <CompositesLayerManager id={LAYERS_KEYS.COMPOSITES} />,
          },
        ]
      : []),
  ]);

  if (!areaName) {
    return null;
  }

  return <DraggableLayerManager {...{ sections, sectionsOrderRecord, setSectionsOrderRecord }} />;
};
