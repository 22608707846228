export enum LAYERS_KEYS {
  EXTENT = 1,
  INFRASTRUCTURE = 2,
  LINEAMENTS = 3,
  INDICES = 4,
  SAM = 5,
  MTMF = 6,
  ML = 7,
  CLUSTERS = 8,
  COMPOSITES = 9,
  DEM = 97,
  HILLSHADE = 98,
  SATELLITE = 99,
}
