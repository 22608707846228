import { TURBO_COLORMAP } from 'domain/exploration/colors';
import { type SpectralClassifierDTO } from 'domain/exploration/types';
import { MineralsLayerManager } from './MineralsLayerManager';
import { useLayersDisplayState } from './useLayersDisplayState';
import { useExplorationLayerManagerStore } from '../../explorationLayerManagerStore';
import { useSamLayersVisibility } from '../useSurfaceLayersVisibility';

interface MineralsLayerManagerSamProps {
  id: number;
  classifiers: SpectralClassifierDTO[];
}

export const MineralsLayerManagerSam = ({ id, classifiers }: MineralsLayerManagerSamProps) => {
  const classifierId = 1;
  const samLayersVisibility = useSamLayersVisibility();
  const samLayersOrderRecord = useExplorationLayerManagerStore.use.samLayersOrderRecord();
  const setSamLayersOrderRecord = useExplorationLayerManagerStore.use.setSamLayersOrderRecord();

  const { classesVisibility, updateClassesVisibility, updateLayerVisibility } = useLayersDisplayState(
    classifierId,
    samLayersVisibility,
  );

  const description = classifiers.find(({ id }) => id === classifierId)?.description;

  return (
    <MineralsLayerManager
      id={id}
      classifierId={classifierId}
      classesVisibility={classesVisibility}
      updateClassesVisibility={updateClassesVisibility}
      updateLayerVisibility={updateLayerVisibility}
      title="minerals - SAM"
      defaultDescription={description || 'Spectral Angle Mapper (SAM)'}
      gradientModeColormap={TURBO_COLORMAP}
      layersOrderRecord={samLayersOrderRecord}
      setLayersOrderRecord={setSamLayersOrderRecord}
    />
  );
};
