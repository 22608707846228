import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { IndentedContentWraper } from 'components/layerManager/styled';
import { LabeledCheckbox } from 'ui';
import { DraggableLayerManagerSection } from 'components/layerManager/draggableLayerManager/DraggableLayerManagerSection';

interface ExtentItemProps {
  id: number;
}

export const ExtentItem = ({ id }: ExtentItemProps) => {
  const isExtentVisible = useLayerManagerStore.use.isExtentVisible();
  const toggleExtentVisibility = useLayerManagerStore.use.toggleExtentVisibility();

  return (
    <DraggableLayerManagerSection {...{ id }}>
      <IndentedContentWraper>
        <LabeledCheckbox
          children="Extent"
          variant="WIDE"
          onChange={toggleExtentVisibility}
          checked={isExtentVisible || false}
        />
      </IndentedContentWraper>
    </DraggableLayerManagerSection>
  );
};
