/* eslint-disable @typescript-eslint/no-explicit-any */
import { type AxiosResponse } from 'axios';
import { type PointHistoryApiResponse } from 'common/types';
import { isExtendedIndicatorValid } from 'common/typeguards';
import {
  type WaterIndicator,
  type WaterAreaResultsApiResponse,
  type WaterBodiesApiResponse,
  type WaterBody,
  type WaterBodyDefaultView,
  type WaterBodyResultCogApiResponse,
  type WaterDatesQueryApiResponse,
  type WaterIndicatorsApiResponse,
  type WaterMask,
  type MoistureResultsApiResponse,
  type MoistureIndicator,
  type SnowCoverIndicator,
  type SnowCoverResultsApiResponse,
} from './types';

export const isWaterIndicatorValid = (indicator: any): indicator is WaterIndicator => {
  return (
    indicator &&
    typeof indicator.id === 'number' &&
    typeof indicator.indicator_name === 'string' &&
    typeof indicator.min_value === 'number' &&
    typeof indicator.max_value === 'number' &&
    typeof indicator.unit === 'string' &&
    typeof indicator.colormap === 'object' &&
    Object.values(indicator.colormap).every(String)
  );
};

export const isWaterIndicatorsResponseValid = (
  response: any,
): response is AxiosResponse<WaterIndicatorsApiResponse> => {
  return Array.isArray(response?.data?.results) && response.data.results.every(isWaterIndicatorValid);
};

export const isWaterBodyValid = (waterBody: any): waterBody is WaterBody => {
  return (
    typeof waterBody?.id === 'number' && typeof waterBody?.name === 'string' && typeof waterBody?.type_id === 'number'
  );
};

export const isWaterBodiesResponseValid = (response: any): response is AxiosResponse<WaterBodiesApiResponse> => {
  return (
    typeof response?.data?.count === 'number' &&
    Array.isArray(response?.data?.results) &&
    response?.data?.results.every(isWaterBodyValid)
  );
};

export const isGetWaterResultsResponse = (response: any): response is WaterAreaResultsApiResponse => {
  return typeof response?.data?.count === 'number' && typeof response?.data?.results === 'object';
};

export const areWaterDatesValid = (data: any): data is WaterDatesQueryApiResponse => {
  return (
    data &&
    Array.isArray(data.data?.results) &&
    data.data.results.every((item: any) => typeof item.img_date === 'string')
  );
};

export const isWaterMaskUrlResponse = (response: any): response is WaterMask => {
  return typeof response?.data?.id === 'number' && typeof response?.data?.url === 'string';
};

export const isSuccessfulWaterbodyDefaultView = (response: any): response is AxiosResponse<WaterBodyDefaultView> => {
  return (
    typeof response.data.lat === 'number' &&
    typeof response.data.lon === 'number' &&
    typeof response.data.zoom === 'number' &&
    typeof response.data.waterbody_id === 'number'
  );
};

export const isSuccessfulChosenPoint = (pointHistory: any): pointHistory is AxiosResponse<PointHistoryApiResponse> => {
  return (
    typeof pointHistory?.data?.count === 'number' &&
    pointHistory?.data?.count > 1 &&
    Array.isArray(pointHistory?.data?.results)
  );
};

export const isGetWaterGeotiffResponse = (response: any): response is WaterBodyResultCogApiResponse => {
  return typeof response?.data?.id === 'number' && typeof response?.data?.url === 'string';
};

export const areMoistureIndicatorsValid = (indicators: any): indicators is MoistureIndicator[] => {
  return (
    Array.isArray(indicators) &&
    indicators.every(
      (indicator) =>
        typeof indicator.opacity === 'number' &&
        typeof indicator.is_multi_layers === 'boolean' &&
        isWaterIndicatorValid(indicator),
    )
  );
};

export const areMoistureResultsValid = (data: any): data is AxiosResponse<MoistureResultsApiResponse, any> => {
  return (
    data &&
    Array.isArray(data?.data?.results) &&
    data.data.results.every(
      (result: any) =>
        typeof result.id === 'number' &&
        typeof result.aoi_id === 'number' &&
        typeof result.aoi_name === 'string' &&
        typeof result.indicator_id === 'number' &&
        typeof result.img_date === 'string' &&
        typeof result.url === 'string' &&
        typeof result.name === 'string' &&
        typeof result.label_name === 'string' &&
        typeof result.color === 'string',
    )
  );
};

export const areSnowCoverIndicatorsValid = (indicators: any): indicators is SnowCoverIndicator[] => {
  return (
    Array.isArray(indicators) &&
    indicators.every(
      (indicator) =>
        typeof indicator.indicator_label === 'string' &&
        typeof indicator.source_url === 'string' &&
        isExtendedIndicatorValid(indicator),
    )
  );
};

export const areSnowCoverResultsValid = (data: any): data is AxiosResponse<SnowCoverResultsApiResponse> => {
  return (
    Array.isArray(data?.data?.results) &&
    data.data.results.every(
      (result: any) =>
        typeof result.id === 'number' &&
        typeof result.aoi_name === 'string' &&
        typeof result.img_date === 'string' &&
        typeof result.img_url === 'string',
    )
  );
};
