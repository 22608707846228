import styled from 'styled-components';

import { Button, ReturnButton, Select } from 'ui';
import { InfoArea, useInfoAreaService } from 'ui/infoArea';
import { useGetAoiName } from 'common/hooks/getAoiName';
import { AppLayerManager } from 'components/layerManager/legacy';
import { LayerManagerControlButton } from 'components/layerManager/LayerManagerControlButton';
import { AreaDetailsLayout } from 'views/areas/areaDetails/AreaDetailsLayout';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { WithCogBandRange } from 'services/titiler/WithCogBandRange';
import { useSnowCoverIndicators } from './useSnowCoverIndicators';
import { SnowCoverLayerManagerLegendItem } from './SnowCoverLayerManagerLegendItem';
import { SnowCoverTimeline } from './SnowCoverTimeline';
import { SnowCoverColorScale } from './SnowCoverColorScale';

export const SnowCover = () => {
  const areaName = useGetAoiName();
  const infoAreaProps = useInfoAreaService();
  const areReportsActive = useGetAreReportsActiveUseCase();

  const { selectedIndicator, indicatorsSelectData, handleIndicatorChange, handleIndicatorClear } =
    useSnowCoverIndicators();

  return (
    <AreaDetailsLayout
      returnButton={
        <ReturnButton visible={!!selectedIndicator} onClick={handleIndicatorClear} label="back to timeline view" />
      }
      legend={
        selectedIndicator ? (
          <WithCogBandRange
            Component={SnowCoverColorScale}
            props={{
              colormap: selectedIndicator.colormap,
              unit: selectedIndicator.unit,
            }}
            url={selectedIndicator.source_url}
            precision={selectedIndicator.precision}
          />
        ) : undefined
      }
      toolbarContent={
        <>
          <InfoArea {...infoAreaProps}>{selectedIndicator?.description}</InfoArea>
          {!selectedIndicator ? (
            <ButtonWrapper>
              <Button variant="LIGHT" onClick={() => handleIndicatorChange(indicatorsSelectData.options[0])}>
                Indicators view
              </Button>
            </ButtonWrapper>
          ) : (
            !areReportsActive && <Select {...indicatorsSelectData} onChange={handleIndicatorChange} />
          )}
        </>
      }
      toolboxContent={!areReportsActive && <LayerManagerControlButton />}
    >
      {!areReportsActive && (
        <AppLayerManager>
          <SnowCoverLayerManagerLegendItem indicator={selectedIndicator} />
        </AppLayerManager>
      )}
      {areaName && <SnowCoverTimeline areaName={areaName} visible={!areReportsActive && !selectedIndicator} />}
    </AreaDetailsLayout>
  );
};

const ButtonWrapper = styled.div`
  z-index: 1500;
`;
