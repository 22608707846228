import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { AuxiliaryControlsWrapper, IndentedContentWraper, LabeledSliderWrapper } from 'components/layerManager/styled';
import { Container, LabeledCheckbox, ModeSwitcher, Slider } from 'ui';
import { ReactComponent as BrightnessIcon } from 'assets/svg/brightness-icon.svg';
import { DraggableLayerManagerSection } from 'components/layerManager/draggableLayerManager';

interface SatelliteItemProps {
  id: number;
}

export const SatelliteItem = ({ id }: SatelliteItemProps) => {
  const isSatelliteVisible = useLayerManagerStore.use.isSatelliteVisible();
  const toggleSatelliteVisibility = useLayerManagerStore.use.toggleSatelliteVisibility();
  const isSatelliteGrayscale = useLayerManagerStore.use.isSatelliteGrayscale();
  const toggleSatelliteGrayscale = useLayerManagerStore.use.toggleSatelliteGrayscale();
  const grayscaleSatelliteBrightness = useLayerManagerStore.use.grayscaleSatelliteBrightness();
  const setGrayscaleSatelliteBrightness = useLayerManagerStore.use.setGrayscaleSatelliteBrightness();

  const onBrightnessChange = (value: number | number[]) => {
    setGrayscaleSatelliteBrightness(Array.isArray(value) ? value[0] : value);
  };

  return (
    <DraggableLayerManagerSection id={id}>
      <IndentedContentWraper>
        <LabeledCheckbox variant="WIDE" onChange={toggleSatelliteVisibility} checked={isSatelliteVisible}>
          satellite
        </LabeledCheckbox>
        <AuxiliaryControlsWrapper disabled={!isSatelliteVisible}>
          <Container pv={4}>
            <ModeSwitcher
              isModeOn={isSatelliteGrayscale}
              label="black & white mode"
              toggleMode={toggleSatelliteGrayscale}
            />
          </Container>
          <LabeledSliderWrapper>
            <BrightnessIcon />
            <Slider
              ariaLabel="Brightness slider"
              marks={{
                '0': '',
                '1': '',
                '2': '',
                '3': '',
              }}
              value={grayscaleSatelliteBrightness}
              onChange={onBrightnessChange}
              defaultValue={1}
              min={0}
              max={3}
              step={1}
            />
          </LabeledSliderWrapper>
        </AuxiliaryControlsWrapper>
      </IndentedContentWraper>
    </DraggableLayerManagerSection>
  );
};
