import { useEffect } from 'react';

import { layerManagerOptionsBuildHelper, useLayerOrderStore } from 'components/layerManager';
import { explorationRepository } from 'infrastructure/exploration/explorationRepository';
import { type SpectralClassifierDTO } from 'domain/exploration/types';
import { useLegendOptions } from './useLegendOptions';
import { MineralsLayerManager } from './MineralsLayerManager';
import { useMlLayersVisibility } from '../useSurfaceLayersVisibility';

interface MineralsLayerManagerMtmfProps {
  id: number;
  classifiers: SpectralClassifierDTO[];
}

export const MineralsLayerManagerMl = ({ id, classifiers }: MineralsLayerManagerMtmfProps) => {
  const classifierId = 3;

  const {
    classesQuery,
    classesVisibility,
    updateClassesVisibility,
    toggleMultipleQueries: updateLayerVisibility,
  } = useMlLayersVisibility();

  const { minerals } = explorationRepository.useFetchMineralsTypes();
  const { legendOptions, preferredOptions } = useLegendOptions(minerals, classifierId);
  const { layersOrderRecord, setLayersOrderRecord } = useLayerOrderStore();

  const description = classifiers.find(({ id }) => id === classifierId)?.description;

  const activatePreferredLayer = () => {
    if (legendOptions.length && preferredOptions.length) {
      updateClassesVisibility(layerManagerOptionsBuildHelper(legendOptions, [preferredOptions[0].id]));
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      activatePreferredLayer();
      return;
    }, 250);

    return () => clearTimeout(timeout);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!classesQuery) {
      activatePreferredLayer();
      return;
    }
  }, [classesQuery, updateClassesVisibility]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MineralsLayerManager
      id={id}
      classifierId={classifierId}
      classesVisibility={classesVisibility}
      updateClassesVisibility={updateClassesVisibility}
      updateLayerVisibility={updateLayerVisibility}
      title="minerals - ML"
      defaultDescription={description || 'Minerals predicated with ML algorhitm'}
      layersOrderRecord={layersOrderRecord}
      setLayersOrderRecord={setLayersOrderRecord}
    />
  );
};
