import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useGetAoiName } from 'common/hooks/getAoiName';
import {
  FoldableSection,
  FoldableSectionCheckbox,
  FoldableSectionContentWrapper,
  LoadingStatePlaceholder,
  REQUEST_MORE_DATA_MESSAGE,
  useFoldableSectionStatus,
} from 'components/layerManager';
import { compositesRepository } from 'infrastructure/composites/compositesRepository';
import { COMPOSITES_INFO_POINT_DESCRIPTION } from 'domain/exploration/configs';
import type { ProviderType } from 'domain/composites/types';
import { ProviderField } from './ProviderField';
import { getBandCombosDataByProvider } from './helpers';
import { useCompositesStore } from '../compositesStore';

interface CompositesLayerManagerProps {
  id: number;
}

export const CompositesLayerManager = ({ id }: CompositesLayerManagerProps) => {
  const aoiName = useGetAoiName();
  const areCompositesVisible = useCompositesStore.use.areCompositesVisible();
  const toggleCompositesVisibility = useCompositesStore.use.toggleCompositesVisibility();
  const providersDataOpacityDict = useCompositesStore.use.dataOpacityDictionary();
  const setProvidersDataOpacityDict = useCompositesStore.use.setDataOpacity();

  const [selectedProvider, setSelectedProvider] = useState<ProviderType>('sentinel');

  const handleProviderSelect = (option: ProviderType) => {
    setSelectedProvider(option);
  };

  const onOptionSelect = (option: ProviderType) => {
    handleProviderSelect(option);
    !areCompositesVisible && toggleCompositesVisibility();
  };

  const handleProviderDataOpacityChange = (provider: ProviderType, value: number | number[]) => {
    if (typeof value === 'number') {
      setProvidersDataOpacityDict(provider, value);
    }
  };

  const clearSelectedBandCombos = useCompositesStore.use.clearSelectedBandCombos();

  useEffect(() => {
    return () => {
      clearSelectedBandCombos();
    };
  }, [aoiName, clearSelectedBandCombos]);

  const { areProvidersLoading, compositesProviders } = compositesRepository.useFetchProviders();

  const { areCompositesResultsLoading, compositesResults } = compositesRepository.useFetchResults();

  const resultsForCurrentArea = compositesResults?.filter((result) => result.aoi_name === aoiName);

  const providersExtractedFromResults = Array.from(new Set(resultsForCurrentArea?.map(({ provider }) => provider)));

  const providersWithData = compositesProviders?.filter(({ name }) => providersExtractedFromResults.includes(name));

  const providersWithDataNames = providersWithData.map(({ name }) => name);

  useEffect(() => {
    if (!providersWithDataNames.includes(selectedProvider)) {
      setSelectedProvider(providersWithData[0]?.name);
    }
  }, [providersWithData, providersWithDataNames, selectedProvider]);

  const { description, areResultsAvailable } = useFoldableSectionStatus({
    defaultDescription: COMPOSITES_INFO_POINT_DESCRIPTION,
    noDataMessage: REQUEST_MORE_DATA_MESSAGE,
    areResultsAvailable: !!resultsForCurrentArea.length,
  });

  if (areProvidersLoading || areCompositesResultsLoading) {
    return <LoadingStatePlaceholder />;
  }

  return (
    <FoldableSection
      id={id}
      headerContent={
        <FoldableSectionCheckbox
          variant="NORMAL"
          title="false color composites"
          description={description}
          isCheckboxChecked={areCompositesVisible && areResultsAvailable}
          onCheckboxToggle={toggleCompositesVisibility}
          disabled={!areResultsAvailable}
        />
      }
      disabled={!areResultsAvailable}
    >
      <FoldableSectionContentWrapper>
        <ProvidersList>
          {providersWithData.map((provider) => (
            <ProviderField
              key={provider.name}
              name={provider.name as ProviderType}
              title={provider.title}
              onOptionSelect={onOptionSelect}
              isSelected={areCompositesVisible && provider.name === selectedProvider}
              bandCombosData={getBandCombosDataByProvider(resultsForCurrentArea, provider.name)}
              opacity={providersDataOpacityDict[provider.name as keyof typeof providersDataOpacityDict]}
              onOpacityChange={handleProviderDataOpacityChange}
            />
          ))}
        </ProvidersList>
      </FoldableSectionContentWrapper>
    </FoldableSection>
  );
};

const ProvidersList = styled.ul`
  list-style: none;
  padding: 0 14px;
`;
