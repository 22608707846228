import { useAoiNumber } from 'common/navigation/hooks';
import { Lineament } from './Lineament';
import { useLineaments } from './useLineaments';
import { useExplorationLayerManagerStore } from '../explorationLayerManagerStore';

interface LineamentsCollectionProps {
  areaName: string;
}

export const LineamentsCollection = ({ areaName }: LineamentsCollectionProps) => {
  const areaId = useAoiNumber();
  const areLineamentsVisible = useExplorationLayerManagerStore.use.areLineamentsVisible();
  const lineamentsOpacity = useExplorationLayerManagerStore.use.lineamentsOpacity();
  const isLineamentsSelectionMode = useExplorationLayerManagerStore.use.isLineamentsSelectionMode();

  const { selectedOption, rangeResult } = useLineaments();

  const buildFileUrlFromName = (name: string) => {
    return `${process.env.REACT_APP_PUBLIC_CONTAINER_URL}/lineaments/${areaName}/${name}.geojson`;
  };

  if (!selectedOption || !rangeResult) {
    return null;
  }

  const id = Number(`${areaId}${selectedOption.join('')}`);
  const name = `${selectedOption[0]}-${selectedOption[1]}`;

  return (
    <Lineament
      key={id}
      id={id}
      url={buildFileUrlFromName(name)}
      name={name}
      isVisible={areLineamentsVisible}
      opacity={lineamentsOpacity}
      isSelectionMode={isLineamentsSelectionMode}
    />
  );
};
