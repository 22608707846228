import { type CSSProperties, type ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { LegendItemWraper } from '../styled';
import { DragIcon } from '../DragIcon';

interface DraggableLayerManagerSectionProps {
  id: number;
  children: ReactNode;
}

export const DraggableLayerManagerSection = ({ id, children }: DraggableLayerManagerSectionProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef, active } = useSortable({
    id,
  });

  const style: CSSProperties = {
    transform: CSS.Translate.toString({
      x: 0,
      y: transform?.y || 0,
      scaleX: transform?.scaleX || 1,
      scaleY: transform?.scaleY || 1,
    }),
    transition,
  };

  return (
    <DraggableLegendItem
      data-testid={`legend-item-${id}`}
      ref={setNodeRef}
      style={active ? style : undefined}
      active={!!active}
      isDragged={active?.id === id}
    >
      <DragIconWrapper ref={setActivatorNodeRef} {...attributes} {...listeners}>
        <DragIcon />
      </DragIconWrapper>
      <LegendItemContent>{children}</LegendItemContent>
    </DraggableLegendItem>
  );
};

const DraggableLegendItem = styled(LegendItemWraper)<{ active: boolean; isDragged: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 10px 16px 10px 0;

  ${({ active, isDragged }) =>
    active &&
    !isDragged &&
    css`
      opacity: 0.6;
    `};

  ${({ active }) =>
    active &&
    css`
      &::before {
        content: '';
        display: block;
        position: absolute;
        inset: 0;
        cursor: move;
        z-index: 2;
      }
    `};
`;

const LegendItemContent = styled.div`
  flex-grow: 1;
  margin-right: -16px;
`;

const DragIconWrapper = styled.div`
  width: 16px;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: transparent;
  cursor: move;
  margin-left: -16px;
`;
