import { MTMF_GRADIENT_COLORMAP } from 'domain/exploration/colors';
import { type SpectralClassifierDTO } from 'domain/exploration/types';
import { MineralsLayerManager } from './MineralsLayerManager';
import { useLayersDisplayState } from './useLayersDisplayState';
import { useExplorationLayerManagerStore } from '../../explorationLayerManagerStore';
import { useMtmfLayersVisibility } from '../useSurfaceLayersVisibility';

interface MineralsLayerManagerMtmfProps {
  id: number;
  classifiers: SpectralClassifierDTO[];
}

export const MineralsLayerManagerMtmf = ({ id, classifiers }: MineralsLayerManagerMtmfProps) => {
  const classifierId = 2;
  const mtmfLayersVisibility = useMtmfLayersVisibility();
  const mtmfLayersOrderRecord = useExplorationLayerManagerStore.use.mtmfLayersOrderRecord();
  const setMtmfLayersOrderRecord = useExplorationLayerManagerStore.use.setMtmfLayersOrderRecord();

  const { classesVisibility, updateClassesVisibility, updateLayerVisibility } = useLayersDisplayState(
    classifierId,
    mtmfLayersVisibility,
  );

  const description = classifiers.find(({ id }) => id === classifierId)?.description;

  return (
    <MineralsLayerManager
      id={id}
      classifierId={classifierId}
      classesVisibility={classesVisibility}
      updateClassesVisibility={updateClassesVisibility}
      updateLayerVisibility={updateLayerVisibility}
      title="minerals - MTMF"
      defaultDescription={description || 'Mixture-tuned Matched Filtering (MTMF)'}
      gradientModeColormap={MTMF_GRADIENT_COLORMAP}
      layersOrderRecord={mtmfLayersOrderRecord}
      setLayersOrderRecord={setMtmfLayersOrderRecord}
    />
  );
};
