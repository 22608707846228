import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { ScrollbarWrapper } from 'ui';

interface Props {
  children: React.ReactNode;
  initialHeight?: number;
}

export const VerticalResizer = ({ children, initialHeight }: Props) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const resizerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const wrapperElement = wrapperRef.current;
    const resizerElement = resizerRef.current;

    if (!wrapperElement || !resizerElement) {
      return;
    }

    const wrapperStyles = window.getComputedStyle(wrapperElement);
    let wrapperHeight = parseInt(wrapperStyles.height, 10);
    let yCord = 0;

    const onResizerMouseMove = (event: MouseEvent) => {
      const dy = event.clientY - yCord;
      wrapperHeight = wrapperHeight - dy;
      yCord = event.clientY;
      wrapperElement.style.height = `${wrapperHeight}px`;
    };

    const onResizerMouseDown = (event: MouseEvent) => {
      yCord = event.clientY;
      document.addEventListener('mousemove', onResizerMouseMove);
      document.addEventListener('mouseup', () => document.removeEventListener('mousemove', onResizerMouseMove));
    };

    resizerElement.addEventListener('mousedown', onResizerMouseDown);

    return () => {
      resizerElement.removeEventListener('mousedown', onResizerMouseDown);
    };
  }, []);

  return (
    <ResizableWrapper initialHeight={initialHeight} ref={wrapperRef} data-testid="vertical-resizer-wrapper">
      <Resizer ref={resizerRef} data-testid="vertical-resizer-handle" />
      <ScrollbarWrapper scrollable>{children}</ScrollbarWrapper>
    </ResizableWrapper>
  );
};

const ResizableWrapper = styled.div<{ initialHeight?: number }>`
  position: relative;
  max-height: 90vh;
  height: ${({ initialHeight }) => (initialHeight ? `${initialHeight}px` : '100%')};
`;

const Resizer = styled.div`
  position: absolute;
  height: 10px;
  left: 0;
  top: 0;
  right: 0;
  cursor: row-resize;
`;
