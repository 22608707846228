import { Fragment } from 'react/jsx-runtime';
import styled from 'styled-components';
import { closestCenter, DndContext } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { useLayerManagerLegendOptionsService } from 'components/layerManager/LegendOptionsService';
import { type DraggableSection } from './types';

interface DraggableLayerManagerProps {
  sections: DraggableSection[];
  sectionsOrderRecord: Record<string, number[]> | null;
  setSectionsOrderRecord: (key: string, layersOrder: number[]) => void;
}

export const DraggableLayerManager = ({
  sections,
  sectionsOrderRecord,
  setSectionsOrderRecord,
}: DraggableLayerManagerProps) => {
  const { dndOptions, handleDragEnd } = useLayerManagerLegendOptionsService(
    sections,
    sectionsOrderRecord,
    setSectionsOrderRecord,
    true,
  );

  return (
    <Wrapper>
      <DndContext
        onDragEnd={handleDragEnd}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      >
        <SortableContext items={dndOptions} strategy={verticalListSortingStrategy}>
          {dndOptions.map(({ id, content }) => (
            <Fragment key={id}>{content}</Fragment>
          ))}
        </SortableContext>
      </DndContext>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0 1rem;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.mineShaftABitLighter};
  border: 1px solid ${({ theme }) => theme.color.primary};
`;
