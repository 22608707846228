import styled from 'styled-components';

import { LoadingDots } from 'ui/atoms/loadingDots';

export const LoadingStatePlaceholder = () => {
  return (
    <LoadingWrapper>
      <LoadingDots />
    </LoadingWrapper>
  );
};

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
  gap: 10px;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding: 24px 12px;
`;
