import { type ColormapDictionary, type LayerRange } from 'common/types';
import { type LeafletBounds } from 'common/types/mapData';
import { buildGradientColormapArray } from 'common/utils/color';
import { DataLayer } from 'components/map';
import { SingleLayerClickToValueTitilerTooltip } from 'components/map/tooltip/SingleLayerClickToValueTitilerTooltip';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { Loading } from 'ui/molecules/loading';
import { getAzureBlobStorageUrl, getBoundsFromCogInfo } from 'services/titiler/utils';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { titilerInfoService } from 'services/titiler/titilerInfoService';

interface Props {
  url: string;
  colormap: ColormapDictionary;
  unit: string;
  precision: number;
  layerRange: LayerRange;
}

export const SnowCoverIndicatorMapLayer = ({ url, colormap, unit, precision, layerRange: { min, max } }: Props) => {
  const isVisible = useLayerManagerStore.use.isDataLayerVisible();

  const expression = `((b1>=${min})&(b1<${max}))*b1`;
  const colormapArray = buildGradientColormapArray(colormap, min, max);

  const titilerUrl = titilerApiAdapter.buildMonocolorCogUrl(url, {
    expression,
    colormap: JSON.stringify(colormapArray),
  });

  const { info, isInfoLoading } = titilerInfoService.useGetInfo(url);
  const bounds = getBoundsFromCogInfo(info) as LeafletBounds | undefined;

  if (isInfoLoading) {
    return <Loading />;
  }

  if (!titilerUrl || !bounds) {
    return null;
  }

  return (
    <>
      <DataLayer
        isVisible={isVisible}
        layerName={'snowCoverLayer'}
        zIndex={431}
        cogUrl={titilerUrl}
        bounds={bounds}
        opacity={1}
      />
      {isVisible && (
        <SingleLayerClickToValueTitilerTooltip url={getAzureBlobStorageUrl(url)} unit={unit} precision={precision} />
      )}
    </>
  );
};
