import { LabeledCheckbox, Slider } from 'ui';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { AuxiliaryControlsWrapper, IndentedContentWraper, LabeledSliderWrapper } from 'components/layerManager/styled';
import { DraggableLayerManagerSection } from 'components/layerManager/draggableLayerManager';
import { type AreaOfInterestTopographyLayer } from 'domain/areas/types';

interface DemItemProps {
  id: number;
  layersList: AreaOfInterestTopographyLayer[];
}

export const DemItem = ({ id, layersList }: DemItemProps) => {
  const isTopographyVisible = useLayerManagerStore.use.isTopographyVisible();
  const toggleTopographyVisibility = useLayerManagerStore.use.toggleTopographyVisibility();
  const topographyOpacity = useLayerManagerStore.use.topographyOpacity();
  const setTopographyOpacity = useLayerManagerStore.use.setTopographyOpacity();

  const onTopographyOpacityChange = (value: number | number[]) => {
    setTopographyOpacity(Array.isArray(value) ? value[0] : value);
  };

  if (!layersList.length) {
    return null;
  }

  return (
    <DraggableLayerManagerSection id={id}>
      <IndentedContentWraper>
        <LabeledCheckbox variant="WIDE" onChange={toggleTopographyVisibility} checked={isTopographyVisible}>
          hypsometric
        </LabeledCheckbox>
        <AuxiliaryControlsWrapper disabled={!isTopographyVisible}>
          <LabeledSliderWrapper>
            opacity
            <Slider
              ariaLabel="Opacity slider"
              marks={{
                '0': '',
                '1': '',
              }}
              value={topographyOpacity}
              onChange={onTopographyOpacityChange}
              defaultValue={1}
              min={0}
              max={1}
              step={0.01}
            />
          </LabeledSliderWrapper>
        </AuxiliaryControlsWrapper>
      </IndentedContentWraper>
    </DraggableLayerManagerSection>
  );
};
